























































import { Component, Vue } from 'vue-property-decorator'
import * as echarts from 'echarts/core'
import { BarChart, LineChart } from 'echarts/charts'
import {
    GridComponent,
    TitleComponent,
    LegendComponent,
    PolarComponent
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import { apiUserIndex } from '@/api/user/user'
echarts.use([
    BarChart,
    GridComponent,
    CanvasRenderer,
    TitleComponent,
    LegendComponent,
    PolarComponent,
    LineChart
])
@Component
export default class UserProfile extends Vue {
    /** S Data **/
    UserData = {
        echarts_data: []
    }
    tableData = []
    option = {
        xAxis: {
            type: 'category',
            data: [0]
        },
        yAxis: {
            type: 'value'
        },
        legend: {
            data: ['人数']
        },
        itemStyle: {
            // 点的颜色。
            color: 'red'
        },
        tooltip: {
            trigger: 'axis'
        },
        series: [
            {
                name: '人数',
                data: [0],
                type: 'line'
                //smooth: true,
            }
        ]
    }
    /** E Data **/

    /** S Methods **/
    apiUserIndexFunc() {
        this.option.xAxis.data = []
        apiUserIndex()
            .then(res => {
                this.UserData = res

                // 清空echarts 数据
                this.option.xAxis.data = []
                this.option.series[0].data = []

                // 写入从后台拿来的数据
                this.UserData.echarts_data.forEach((item: any) => {
                    this.option.xAxis.data.push(item.day)
                    this.option.series[0].data.push(item.user_new_count)
                })
            })
            .catch(() => {
                this.$message.error('请求数据失败，请刷新重载!')
            })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        // this.apiUserIndexFunc()
    }
    mounted() {
        this.apiUserIndexFunc()
    }
    /** E Life Cycle **/
}
